import styled from 'styled-components'

const Column = styled.div`
    ${({ xs }) => xs && `
        flex: 0 0 ${(xs / 12) * 100}%;
        max-width: ${(xs / 12) * 100}%;
    `}
    ${({ sm }) => sm && `
        @media (min-width: 544px) {
            flex: 0 0 ${(sm / 12) * 100}%;
            max-width: ${(sm / 12) * 100}%;
        }
    `}
    ${({ md }) => md && `
        @media (min-width: 768px) {
            flex: 0 0 ${(md / 12) * 100}%;
            max-width: ${(md / 12) * 100}%;
        }
    `}
    ${({ lg }) => lg && `
        @media (min-width: 992px) {
            flex: 0 0 ${(lg / 12) * 100}%;
            max-width: ${(lg / 12) * 100}%;
        }
    `}
`

export default Column;