import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import theme from '../theme.js';
import Navigation from '../components/Navigation';
import BackgroundWrapper from '../components/BackgroundWrapper';
import Wrapper from '../components/Wrapper';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import FAQ from '../components/FAQ';
//import Stats from '../components/Stats';
import Features from '../components/Features';

export default function Home() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Disposely - Detect Disposable Emails</title>
        <meta name="description" content="Temporary/disposable email addresses are commonly used for spam, fraudulent payments, trial abuse and much more. We’re here to help you detect them." />
      </Helmet>
      <BackgroundWrapper>
        <Wrapper>
          <Navigation />
          <Hero />
          {/* <Stats /> */}
          <Features />
          <Pricing />
          {/* <FAQ /> */}
        </Wrapper>
      </BackgroundWrapper>
    </ThemeProvider>
  );
}
