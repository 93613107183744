const theme = {
    colors: {
        primary: '#0055FF',
        primaryHover: '#024ce0',
        darkText: '#061027',
        bodyText: '#515868'
    },
    fonts: {
        circularBook: 'Baiser-Circle, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        basierCircle: 'Baiser-Circle, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        circularBold: 'Circular-Bold, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        circularMedium: 'Circular-Medium, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    }
};

export default theme;