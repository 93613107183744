import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    margin-top: 120px;

    @media (max-width: 500px) {
        margin-top: 60px;
    }
`

const Header = styled.h3`
    font-weight: 600;
    font-size: 38px;
    color: ${props => props.theme.colors.darkText};
    letter-spacing: 0.96px;
    line-height: 65px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`

const FAQ = (props) => {
    return (
        <Container>
            <Header id="FAQ">
                Frequently Asked Questions
            </Header>
        </Container>
    );
}

export default FAQ;