import React from 'react';
import styled from 'styled-components'
import TextLoop from 'react-text-loop';
import Code from '../../assets/code.svg';

const Container = styled.div`
    display: flex;
    margin-bottom: 80px;

    @media (max-width: 500px) {
        margin-bottom: 40px;
    }
`

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
`;

const RightSide = styled.div`
    width: 110%;
    margin-top: 80px;

    @media (max-width: 600px) {
        display: none;
    }
`;

const Header = styled.h1`
    font-family: ${props => props.theme.fonts.circularBold};
    font-size: 64px;
    color: ${props => props.theme.colors.darkText};
    letter-spacing: 0.96px;
    line-height: 65px;
    margin-top: 100px;

    @media (max-width: 600px) {
        font-size: 52px;
        margin-top: 0;
    }
`

const HeaderTextLoopContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const HeaderTextLoop = styled.div`
    color: ${props => props.theme.colors.primary};
    margin-right: 10px;
`

const Description = styled.div`
    font-size: 17px;
    color: ${props => props.theme.colors.bodyText};
    letter-spacing: 0.29px;
    line-height: 22px;
`;

const ButtonContainer = styled.div`
    margin-top: 60px;
`;

const SignUpButton = styled.a`
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
    color: #FFF;
    padding: 15px 60px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.31px;
    text-align: center;
    text-decoration: none;

    :hover {
        background: ${props => props.theme.colors.primaryHover};
    }
`;

const Navigation = (props) => {
    return (
        <Container>
            <LeftSide>
                <Header>
                    Detect
                    <HeaderTextLoopContainer>
                        <TextLoop interval={5000} adjustingSpeed={1000}>
                            <HeaderTextLoop>
                                Disposable
                            </HeaderTextLoop>
                            <HeaderTextLoop>
                                Temporary
                            </HeaderTextLoop>
                            <HeaderTextLoop>
                                Burner
                            </HeaderTextLoop>
                        </TextLoop>
                        <HeaderTextLoop>
                            Email
                        </HeaderTextLoop>
                    </HeaderTextLoopContainer>
                    addresses
                </Header>
                <Description>
                    Temporary/disposable email addresses are commonly used for spam, fraudulent payments, trial abuse and much more. We’re here to help you detect them.
                </Description>
                <ButtonContainer>
                    <SignUpButton href="/signup">
                        Sign Up
                    </SignUpButton>
                </ButtonContainer>
            </LeftSide>
            <RightSide>
                <Code />
            </RightSide>
        </Container>
    );
}

export default Navigation;