import React, { useState } from 'react';
import styled from 'styled-components';
import Column from './Column';
import Row from './Row';

const Container = styled.div`
    display: flex;
    margin-top: 120px;
    flex-direction: column;

    @media (max-width: 500px) {
        margin-top: 60px;
    }
`

const Header = styled.h3`
    font-weight: 600;
    font-size: 38px;
    color: ${props => props.theme.colors.darkText};
    letter-spacing: 0.96px;
    line-height: 65px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Th = styled.th`
    text-align: left;
    color: ${props => props.theme.colors.bodyText};
    //font-family: BasierCircle-SemiBold;
    font-size: 16px;
    letter-spacing: 0.25px;
    padding: 10px 20px;
`;

const PriceRow = styled.tr`
   ${props => props.active && `
        //box-shadow: inset 0 0 0px 1px #0055FF;
        box-shadow: inset 0 0 0px 2px rgb(0 85 255 / 0.7);
        background: rgba(0,85,255,0.08);
        border-radius: 4px;
   `} 

    tr:first-child td:first-child { border-top-left-radius: 10px; }
    tr:first-child td:last-child { border-top-right-radius: 10px; }
    tr:last-child td:first-child { border-bottom-left-radius: 10px; }
    tr:last-child td:last-child { border-bottom-right-radius: 10px; }
`;

const Td = styled.td`
    padding: 12px 20px;
    font-size: 16px;
    color: #515868;
    letter-spacing: 0.29px;
`;

const InputLabel = styled.div`
    font-weight: 600;
    font-size: 18px;
    color: #515868;
    letter-spacing: 0.33px;
    margin-bottom: 15px;
`;

const Input = styled.input`
    font-size: 20px;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #f3f3f3;
    border: 1px solid #dcdcdc;
    color: #4c4c4c;
`;

const ChecksContainer = styled.div`
    @media (max-width: 769px) {
        margin-bottom: 30px;
        width: 100%;
        text-align: center;
    }
`;

const prices = [
    { checks: 250, pricePerCheck: 0, price: 'Free' },
    { checks: 5000, pricePerCheck: '$0.0016', price: '$7.99' },
    { checks: 25000, pricePerCheck: '$0.00076', price: '$18.99' },
    { checks: 100000, pricePerCheck: '$0.00029', price: '$29.99' },
    { checks: 250000, pricePerCheck: '$0.00019', price: '$49.99' },
    { checks: 1000000, pricePerCheck: '$0.000099', price: '$99.99' },
    { checks: 'Need More?', pricePerCheck: null, price: 'Contact Us' }
]

const Pricing = (props) => {
    const [checkCount, setCheckCount] = useState(0);
    const [checked, setChecked] = useState(0);

    return (
        <Container>
            <Header id="pricing">
                Pricing
            </Header>
            <Row>
                <Column md="4" sm="12" xs="12">
                    <ChecksContainer>
                        <InputLabel>Checks</InputLabel>
                        <Input type="number" value={checkCount} onChange={event => {
                            const value = parseFloat(event.target.value);
                            setCheckCount(parseFloat(event.target.value));

                            if (value === 0 || value === '' || isNaN(value)) {
                                return setChecked(0);
                            }

                            if (value > prices[prices.length - 2].checks) {
                                return setChecked(prices.length - 1);
                            }

                            for (var i = 0; i < prices.length - 1; i++) {
                                setChecked(i)
                                if (prices[i].checks >= value) {
                                    break;
                                }
                            }
                        }} />
                    </ChecksContainer>
                </Column>
                <Column md="8" sm="12" xs="12">
                    <Table>
                        <thead>
                            <tr>
                                <Th>Checks</Th>
                                <Th>Price per check</Th>
                                <Th>Price</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {prices.map((p, i) => (
                                <PriceRow active={checked === i}>
                                    <Td>{p.checks.toLocaleString('en-US', { maximumFractionDigits: 0 })}</Td>
                                    <Td>{p.pricePerCheck}</Td>
                                    <Td>{p.price}</Td>
                                </PriceRow>
                            ))}
                        </tbody>
                    </Table>
                </Column>
            </Row>
        </Container>
    );
}

export default Pricing;