import React from 'react';
import styled from 'styled-components'
import Row from './Row';
import Column from './Column';

const Feature = styled.div`
    text-align: center;
    margin-top: 20px;
    padding: 12px 20px;
`

const Header = styled.div`
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
    margin-top: 40px;
    font-size: 24px;
    letter-spacing: 0.44px;
    text-align: center;
`

const Description = styled.div`
    color: ${props => props.theme.colors.bodyText};
    font-size: 24px;
    font-size: 17px;
    letter-spacing: 0.31px;
    line-height: 24px;
    margin-top: 20px;
`

const Features = (props) => {
    return (
        <Row>
            <Column md={4}>
                <Feature>
                    <Header>
                        Constantly Updated
                    </Header>
                    <Description>
                        Disposely blocks over 114,000 domains. Disposable email providers are constantly adding new domains to circumvent publicly available blacklists, but Disposely is checking for new domains in near-realtime.
                    </Description>
                </Feature>
            </Column>
            <Column md={4}>
                <Feature>
                    <Header>
                        Cheap and Reliable
                    </Header>
                    <Description>
                        Disposely is built on modern scalable infrastructure allowing us to offer a highly reliable service with unbeatable prices as low as just $0.09 USD per 1,000 checks!
                    </Description>
                </Feature>
            </Column>
            <Column md={4}>
                <Feature>
                    <Header>
                        Why?
                    </Header>
                    <Description>
                        Disposable emails are a common nuisance to website operators due to their use for fraudulent payments, free trial abuse, registration spam and more. Disposely makes it easy to detect them.
                    </Description>
                </Feature>
            </Column>
        </Row>
    );
}

export default Features;