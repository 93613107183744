import styled from 'styled-components'

const BackgroundWrapper = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='772' height='676' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M802.69 0l76.699 3.8 21.48 205.36 41.612 546.468c-97.726-42.353-192.02-88.734-282.88-139.14-136.292-75.612-135.178-64.71-233.327-157.398-98.148-92.688-65.984-109.458-155.233-209.546C185.03 153.084 43.795 87.32.541 0L802.69 0z' fill='%2305F' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 700px;

    @media (max-width: 1000px) {
        background-size: 50%;
    }
`

export default BackgroundWrapper;

